import { EBrand } from '@/types/brand';

export const getProductProperties = (
  propertyList: any,
  manufacturer: EBrand
) => {
  let productColorName = '';
  let productMaterial = '';
  let productAdditionalInfo = '';
  let productShoeType = '';
  let productShoeClosure = '';

  propertyList.forEach((property: any) => {
    if (
      property.group.translated.customFields
        ?.product_detail_use_color_in_product_name
    ) {
      productColorName = property.translated?.name;
    }

    if (
      property.translated.customFields
        .custom_pdp_plp_additional_text_pdp_product_name_plp_product_additional_title
    ) {
      productAdditionalInfo =
        property.translated.customFields
          ?.custom_pdp_plp_additional_text_pdp_product_name_plp_product_additional_title;
    }

    if (
      (manufacturer === EBrand.THINK || manufacturer === EBrand.LEGERO) &&
      property.group.translated.customFields
        .product_detail_use_material_in_product_name
    ) {
      productMaterial = property.translated?.name;
    }

    if (
      property.group.translated.customFields
        .product_detail_use_shoetype_in_product_name
    ) {
      productShoeType = property.translated?.name;
    }

    if (
      property.group.translated.customFields
        .product_detail_use_fastener_in_product_name &&
      manufacturer === EBrand.SUPERFIT
    ) {
      productShoeClosure = property.translated?.name;
    }
  });

  return {
    productColorName,
    productMaterial,
    productAdditionalInfo,
    productShoeType,
    productShoeClosure,
  };
};
