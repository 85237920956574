// import {
//   Product, LineItem
// } from '@shopware-pwa/types';

import { Product } from '@shopware-pwa/types';

let newFlagColor = 'gray';
let saleFlagColor = 'gray';
let awardedFlagColor = 'gray';
let madeInEuropeFlagColor = 'gray';
let barefitFlagColor = 'gray';

interface IBadge {
  value: string;
  position?: number | undefined;
  flagColor?: string;
}

export const composeProductBadges = (
  // product: Product | LineItem,
  product: Product | null,
  translate: any
): IBadge[] => {
  const badgeList: IBadge[] = [];

  // Badges color
  // setting the colors for flags accordning to manufacturer (customFields on manufacturer)
  // FIXME: product?.manufacturer?.TRANSLATED?.name, but "Property 'translated' does not exist on type 'ProductManufacturer'.ts(2339)".

  if (product?.manufacturer?.name == 'legero') {
    newFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_new_legero
    );
    saleFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_sale_legero
    );
    awardedFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_awarded_legero
    );
  }
  if (product?.manufacturer?.name == 'think') {
    newFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_new_think
    );
    saleFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_sale_think
    );
    awardedFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_awarded_think
    );
  }
  if (product?.manufacturer?.name == 'superfit') {
    newFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_new_superfit
    );
    saleFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_sale_superfit
    );
    awardedFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_awarded_superfit
    );
    madeInEuropeFlagColor = String(
      product.manufacturer.customFields
        ?.custom_flags_colors_made_europe_superfit
    );
    barefitFlagColor = String(
      product.manufacturer.customFields?.custom_flags_colors_barefit_superfit
    );
  }

  // new
  // if (product?.isNew || product?.payload?.isNew) {
  // show the NEW badge only if product not on sale
  if (
    (product?.isNew && !product.calculatedPrice?.listPrice?.price) ||
    // eslint-disable-next-line
    // @ts-ignore
    (product?.payload?.isNew && !product.calculatedPrice?.listPrice?.price)
  ) {
    badgeList.push({
      value: translate('types.badges.new'),
      position: 1,
      flagColor: newFlagColor,
    }); // TODO: translations
  }

  // sale
  // info: isCloseout does not exist in product slider product
  if (
    (product && product.calculatedPrice?.listPrice?.price) ||
    // eslint-disable-next-line
    // @ts-ignore
    (product?.payload?.isCloseout && product.price?.listPrice?.price)
  ) {
    let value = translate('types.badges.sale');
    const percentage = Math.round(
      product.calculatedPrice?.listPrice?.percentage
    );

    if (percentage) {
      value += ' -' + String(percentage) + '%';
    }

    badgeList.push({
      value: value,
      position: 1,
      flagColor: saleFlagColor,
    });
  }

  // LSWS-804, LSWS-244

  const productWithProperties = product as Product;
  const propertiesObj = productWithProperties.properties;

  propertiesObj?.filter((el) => {
    if (el.customFields && el.customFields.custom_pdp_plp_awarded_badge) {
      badgeList.push({
        value: translate('types.badges.awarded'),
        position: el.position,
        flagColor: awardedFlagColor,
      });
      // expectedArray.push(el);
    }
    if (
      el.customFields &&
      el.customFields.custom_pdp_plp_made_in_europe_badge
    ) {
      // made in europe  (only superfit)
      badgeList.push({
        value: translate('types.badges.europe'),
        position: el.position,
        flagColor: madeInEuropeFlagColor,
      });
      // expectedArray.push(el);
    }
    if (el.customFields && el.customFields.custom_pdp_plp_barefit_badge) {
      // barefit (only superfit)
      badgeList.push({
        value: translate('types.badges.barefit'),
        position: el.position,
        flagColor: barefitFlagColor,
      });
      // expectedArray.push(el);
    }
  });

  // Sort badges based on their position in ascending order
  badgeList.sort((a, b) => {
    const positionA = a.position || 0;
    const positionB = b.position || 0;
    return positionA - positionB;
  });

  // limit to show only 2 badges
  if (badgeList.length >= 2) {
    return [badgeList[0], badgeList[1]];
  } else {
    return badgeList;
  }

  // return badgeList;
};
